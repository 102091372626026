import React, { useState } from "react";
import W3ALogo from "../../images/w3a_logo.png";
import { FaBars, FaTimes } from 'react-icons/fa'
import { Nav, NavLogo, MobileIcon, NavMenu, NavItem, NavLink } from "./Navbar.style";

const Navbar = () => {

  const [click, setClick] = useState(false)
  const [navbg, setNavbg] = useState(false)
  const handleClick = () => setClick(!click)
  const closeMenu = () => setClick(false)

  const changeNavbg = () => {
    if(window.scrollY >= 80)
      setNavbg(true)
    else
        setNavbg(false)
  }
  window.addEventListener('scroll', changeNavbg)

  return (
    <Nav navbg={navbg}>
      <NavLogo to='/' spy={true} smooth={true} offset={0} duration={500}>
        <img src={W3ALogo} alt='W3A logo'/>
      </NavLogo>
      <MobileIcon onClick={handleClick}>
        {click ? (<FaTimes size={30} style={{ color: '#FFD966' }} />)
               : (<FaBars size={30} style={{ color: '#FFD966' }} />)}
      </MobileIcon>
      <NavMenu click={click}>
        <NavItem>            
          <NavLink to='hero' spy={true} smooth={true} offset={0} duration={500} onClick={closeMenu}>Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to='about' spy={true} smooth={true} offset={5} duration={500} onClick={closeMenu}>Who</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to='why' spy={true} smooth={true} offset={5} duration={500} onClick={closeMenu}>Why</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to='how' spy={true} smooth={true} offset={5} duration={500} onClick={closeMenu}>How</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to='contact' spy={true} smooth={true} offset={-6} duration={500} onClick={closeMenu}>Contact</NavLink>
        </NavItem>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
