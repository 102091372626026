import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS} from 'react-scroll';

export const Nav = styled.nav`
background: ${({ navbg }) => (navbg ? "rgba(0,15,18,0.8)" : "rgba(0,15,18,0.1)")};;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 2rem;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
`;

export const NavLogo = styled(LinkR)`
  display: flex;
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  justify-self: flex-start;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    color: #FFD966;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  position: relative;
  text-decoration: none;

  @media (max-width: 768px) {
    background: rgba(0,15,18,0.9);
    position: fixed;
    top: ${({ click }) => (click ? "80px" : "-100%")};
    left: 0px;
    z-index: 999;
    flex-direction: column;
    transition: 0.3s ease-in;
    width: 100%;
  }
`;

export const NavItem = styled.li`
  height: 40px;
  margin: 1rem 2rem;
`;

export const NavLink = styled(LinkS)`
  color: #FFD966;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem;

  &:hover {
    color: #01bf71;
    font-weight: 1000;
  }
  &.active {
    border-bottom: 3px solid #FFD966;
  }
`;
