import styled from 'styled-components';

export const HeroContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top;
    background: linear-gradient(to right, rgba(23,88,116,.7), rgba(0,15,18,0.5), rgba(0, 15, 18, 0.9));
    background-size: cover;
`;

export const HeroPara = styled.div`
position: absolute;
display: flex;
justify-content: center;
align-items: center;
height: 100%;
transform-style: preserve-3d;
z-index: -1;
`;

export const HeroVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;

/*  @media screen and (max-width: 768px) {
    display: none;
  }*/
`;

export const HeroWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  padding: 0 calc((100vw - 1200px) / 2);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 15, 18, 0.7), rgba(0, 15, 18, 0.9));
  color: #fff;

  @media screen and (max-width: 1300px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const HeroH1 = styled.h1`
  font-size: clamp(2.5rem, 5vw, 3rem);
  line-height: clamp(3rem, 5vw, 5rem);
  font-weight: 300;
  text-align: right;
  letter-spacing: 1px;
  margin-top: 1rem;
`;

export const HeroP = styled.p`
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  text-align: right;
  letter-spacing: 1.1px;
  margin-top: 0.6rem;
`;

export const HeroBtn = styled.button`
  font-size: 1.4rem;
  font-weight: 200;
  padding: 0.5rem 1.5rem;
  border: 2px solid #FFD966;
  border-radius: 4px;
  background: #FFD966;
  color: #000;
  transition: 0.2s ease-out; 
  margin-top: 2rem;
  float:right;

  &:hover {
    background: transparent;
    transition: 0.3s ease-out;
    transform: scale(1.08);
    cursor: pointer;
    color: #FFD966;
  }
`;

export const HeroLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const HeroBoxes = styled.div`
  display: flex;
  position: absolute;
  height: 50px;
  width: 50px;
  border: 1px solid #5DA2BB;
  background: transparent;

`;
