//          <HeroH11>The World Web3 Alliance</HeroH11>
//          <HeroH1>A DAO for</HeroH1>
//          <HeroH1>Universal Connectivity and Consensus</HeroH1>

import videoBg from '../../images/videobg.mp4';
import {
  HeroContainer,
  HeroVideo,
  HeroWrapper,
  HeroH1,
  HeroP,
  HeroLink,
  HeroBtn
} from './Hero.style';
/*      <HeroVideo preload="true" autoPlay loop muted playsinline type="video/mp4" src={videoBg} />
        <source src={videoBg} type="video/mp4"></source>
      </HeroVideo>
*/

const Hero = () => {

  return (
    <HeroContainer id='hero'>
      <HeroVideo id="BgVideo" preload="true" autoPlay loop muted playsInline src={videoBg} />
      <HeroWrapper>
        <HeroH1>The World Web3 Alliance</HeroH1>
        <HeroP>A DAO for Web3 </HeroP>
        <HeroP>Development and Adoption</HeroP>
        <HeroLink
          href="//www.discord.gg/Pwy6j63sTV "
          target="_blank"
          aria-label="Discord"
          title="Join the Alliance"
          rel="noopener noreferrer"
        >
          <HeroBtn>Join W3A DAO Now!! </HeroBtn>
        </HeroLink>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default Hero;
