import styled from 'styled-components';

export const SecContainer = styled.div`
  color: #fff;
  background: linear-gradient(135deg, rgba(51, 63, 80, 0.3), rgba(51, 63, 80, .6), rgba(51, 63, 80, 1), rgba(51, 63, 80, 1));
  background: url(${({ bgPlain, bgImg }) => (bgPlain ? 'rgba(0, 0, 0, 0.7)' : bgImg)});
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 725px;
`;

export const SecWrapper = styled.div`
  background: ${({ lightBg, textLeft }) => (lightBg ? 
              'transparent' : 
              (textLeft ? 
                'linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))' :
                'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))'))};
  max-width: 780px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ textTop }) => (textTop ? 'top' : 'center')};
  align-items: left;
  float: ${({ textLeft }) => (textLeft ? 'left' : 'right')};
  padding: 150px 100px 50px 100px;
  color: #fff;

  @media screen and (max-width: 1300px) {
    padding: 100px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 100px 20px;
  }
`;
 
export const TopLine = styled.p`
  color: #01bf71;
  /*${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#4B59F7')};*/
  font-size: 22px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 520px;
  margin-bottom: 35px;
  font-size: 22px;
  line-height: 35px;
  color: ${({ darkText }) => (darkText ? '#dae1eb' : '#1c2237')};

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  `;