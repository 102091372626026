import { Link } from 'react-scroll';
import { Button } from '../../globalStyles';
import { SecContainer, SecWrapper, TopLine, Heading, Subtitle } from './Section.style';

const Section = (
    {
        id, 
        bgPlain,
        bgImg,
        primary,
        lightBg,
        textLeft,
        textTop,
        topLine,
        lightText,
        darkText,
        headline,
        description,
        linkTo,
        buttonDisp,
        buttonLabel
    }
) => {
    
  return ( 
    <>
      <SecContainer bgPlain={bgPlain} bgImg={bgImg} id={id}>
        <SecWrapper lightBg={lightBg} textTop={textTop} textLeft={textLeft} >
          <TopLine>{topLine}</TopLine>
          <Heading lightText={lightText}>{headline}</Heading>
          <Subtitle darkText={darkText}>{description}</Subtitle>
          {buttonDisp ?
             (<Link to={linkTo} spy={true} smooth={true} offset={0} duration={500} >
               <Button big fontBig primary={primary}>
                 {buttonLabel}
               </Button>
             </Link>) : 
             (<></>)}
        </SecWrapper>
      </SecContainer>
    </>  
  );
}
 
export default Section ;