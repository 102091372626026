import styled from "styled-components";
import bgImg from '../../images/avatar1.jpg'

// Styles

export const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* background: rgba(0,32,52,1); */
  background: url(${bgImg});
  background-size: cover;
  text-align: center;
`;

export const TextWrapper = styled.div`
  width: 500px;
  padding: 4.5rem 2rem 2rem 2rem;
  float: right;  
`;

export const Heading = styled.h1`
  font-size: 2.8rem;
  font-weight: 600;
  color: #fff;
`;

export const ContactForm = styled.div`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 1rem;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 200px;
      min-height: 160px;
      padding: 1rem;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 1rem;
      height: 45px;
      padding: 0.7rem 0 2rem 0;
      font-size: 1.5rem;
      cursor: pointer;
      background: #FFD966;
      color: black;
      border: 2px solid #FFD966;

      &:hover {
        background: transparent;
        transition: 0.3s ease-out;
        transform: scale(1.08);
        cursor: pointer;
        color: #FFD966;
      }
    
    }
  }
`;