import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ContactForm, TextWrapper, Heading, ContactContainer } from './Contact.style';
  
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r584ma6",
        "template_z4b9fl4",
        form.current,
       "i08GsqjLQ2jRMESg-"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          alert('Successfully sent');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <ContactContainer id='contact'>
      <TextWrapper>
        <Heading>Contact Us</Heading>
        <ContactForm>
            <form ref={form} onSubmit={sendEmail}>
                <label>Name</label>
                <input type="text" name="name" />
                <label>Company</label>
                <input type="text" name="company" />
                <label>Email</label>
                <input type="email" name="email" />
                <label>Message</label>
                <textarea name="message" />
                <input type="submit" value="Send" />
            </form>
        </ContactForm>
      </TextWrapper>
    </ContactContainer>
  );
};

export default Contact;
