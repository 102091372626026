import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import { Navbar, Hero, WhySection, Section, Contact, Footer } from './components';
import { section1, section2, section3 } from './Data';

function App() {

  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Hero />
      <Section {...section1} />
      <WhySection />
      <Section {...section3} />
      <Contact />
      <Footer />
    </Router>
  );
}

export default App;
