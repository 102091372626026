import Web1 from '../../images/web1.png'
import Web2 from '../../images/web2.png'
import Web3 from '../../images/web3.png'
import { SecContainer, SecWrapper, SecRow, SecColumn, TextWrapper, TopLine, Heading, Subtitle,
         Honeycomb, HexArea, HexArea2, HexArea3, Hexagon, HexagonBig, HexImg, HexTitle, HexText
       } from './WhySection.style';

const WhySection = () => {
  return (
    <SecContainer id='why'>
      <SecWrapper>
        <SecRow>
            <SecColumn>
              <TextWrapper>
                <TopLine>Why Do We Do That</TopLine>
                <Heading>Secured • Portable • Interoperable</Heading>
                <Subtitle>
                    The metaverse has no place for singularity and silos.
                    Data PORTABILITY and INTEROPERABILITY is paramount to the next gen internet and www, 
                    bringing value to both users and developers.
                </Subtitle>
              </TextWrapper>
            </SecColumn>
            <SecColumn>
              <Honeycomb>
                <HexArea>
                    <Hexagon>
                        <HexTitle>Web1</HexTitle>
                        <HexImg src={Web1} alt='web1'/>
                        <HexText>
                            <br/>Read Only<br/>Data Only<br/>Static Page<br/>Decentralized
                        </HexText>
                    </Hexagon>
                </HexArea>
                <HexArea2>
                    <HexagonBig>
                        <HexTitle>Web3</HexTitle>
                        <HexImg src={Web3} alt='web3'/>
                        <HexText>
                            Read & Write<br/>
                            Data Sovereignty to User<br/>
                            Data Portable and Reusable<br/>
                            Interoperable <br/>
                            User Centric<br/>
                            Creator Economy<br/>
                            Secured and Private <br/>
                            Decentralized
                        </HexText>
                    </HexagonBig>
                </HexArea2>
                <HexArea3>
                    <Hexagon>
                        <HexTitle>Web2</HexTitle>
                        <HexImg src={Web2} alt='web2'/>
                        <HexText>
                            Read & Write<br/>
                            Dynamic Information<br/>
                            Data Economy<br/>
                            Application Centric<br/>
                            Application Silos<br/>
                            Centralized</HexText>
                    </Hexagon>
                </HexArea3>
              </Honeycomb>
            </SecColumn>
        </SecRow>
      </SecWrapper>
    </SecContainer>
  )
}

export default WhySection ;