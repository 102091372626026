import styled, { createGlobalStyle } from 'styled-components';
import "@fontsource/raleway";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }
  body {
    font-family: 'Raleway', 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 200;
    --dark-bg: #262626;
    --gray-1: #BCB4B4;
    --deep-dark: #1E1E1E;
    --gray-2: #363636;
    --white : white;
    --black: black;
  }

  .GradientText{
    background: linear-gradient(160deg, rgb(113, 240, 210), rgb(108, 115, 217));
    background-clip: text;
    display: inline-block;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? '#4B59F7' : '#FFD966')};
  border: 2px solid #FFD966;
  white-space: nowrap;
  padding: ${({ big }) => (big ? '0.8rem 2rem' : '10px 10px')};
  color: #000;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  cursor: pointer;

  &:hover {
    background: transparent;
    transition: 0.3s ease-out;
    transform: scale(1.08);
    cursor: pointer;
    color: #FFD966;
  }
`;

export default GlobalStyle;