import styled from "styled-components";
import bgImg from '../../images/about.png'

export const SecContainer = styled.div`
  color: #000;
/*  background: linear-gradient(135deg, rgba(244, 244, 244, 0.3), rgba(244, 244, 244, .6), rgba(214, 220, 229, 1), rgba(214, 220, 229, 1));*/
  background: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7) ), url(${bgImg});
  background-size: cover;
  padding: 120px 100px 50px 100px;
  height: 725px;

  @media screen and (max-width: 1300px) {
    padding: 100px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 100px 20px 0 20px;
    height: 70%;
}
`;

export const SecWrapper = styled.div`
  display: flex;
  width: 100%
  justify-content: center;
`;

export const SecRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: left;
  justify-content: center;
  flex-direction: row;
`;

export const SecColumn = styled.div`
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    }
`;

export const TextWrapper = styled.div`
  padding-top: 60px;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
    width: 100%;
    max-width: 450px;
  }
`;

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 22px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;
  color: #1c2237;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 520px;
  margin-bottom: 35px;
  font-size: 22px;
  line-height: 35px;
  color: #1c2237;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

  export const Honeycomb = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0 10px 200px;
    float: right;
    align-items: center;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
  `;

  export const HexArea = styled.div`
    display: flex;
  `;

  export const HexArea2 = styled(HexArea)`
    transform: translateY(-214px) translateX(-282px);
    @media screen and (max-width: 768px) {
        transform: translateY(-30px) translateX(0);
    }
  `;

  export const HexArea3 = styled(HexArea)`
    transform: translateY(-435px);
    @media screen and (max-width: 768px) {
        transform: translateY(-75px);
    }
  `;

  export const Hexagon = styled.div`
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 10px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 44%, 75% 86%, 25% 86%, 0 43%);
    background: rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: top;
    align-items: center;
    cursor: pointer;
    transition: 0.5s
    box-shadow: 15px 15px 15px 15px rgba(0,0,0,1);
  `;

  export const HexagonBig = styled.div`
    position: relative;
    width: 400px;
    height: 400px;
    margin: 0 10px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 44%, 75% 86%, 25% 86%, 0 43%);
    background: rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    padding: 5px 0 0 0;
    justify-content: top;
    align-items: center;
    cursor: pointer;
    transition: 0.5s
    /*    box-shadow: 15px 15px 15px 15px rgba(0,0,0,0.4);*/
    `;

  export const HexImg = styled.img`
    object-position: center;
    background: rgba(255,255,255,1);
    z-index: 999;
    &:hover {
        display: none;
        transition: 0.9s ease-in;
    }
`;

  export const HexTitle = styled.h1`
  font-size: 1.2rem;
  color: #1c2237;
`;

  export const HexText = styled.p`
  padding-top: 20px;
  text-align: center;
  line-height: 1.8rem;
  font-size: 1.2rem;
  &:hover {
    opacity: 100%;
    transition: 0.9s ease-in;
}

`;