export const section1 = {
    id: 'about',
    bgPlain: false,
    bgImg: require('./images/about1.png').default,
    primary: false,
    lightBg: false,
    textLeft: true,
    textTop: false,
    lightText: true,
    darkText: true,
    topLine: 'Who We Are',
    headline: 'The World Web3 Alliance',
    description: 'We are the W3A DAO for Universal Connectivity and Consensus, returning data and connectivity to users.  Create an OPEN, SECURED and INCLUSIVE metaverse where everyone has equal and fair opportunity to be who they want to be.',
    buttonDisp: false,
    buttonLabel: 'Contact Us',
  };
  
export const section2 = {
    id: 'why',
    bgPlain: false,
    bgImg: require('./images/about.png').default,
    primary: false,
    lightBg: true,
    textLeft: false,
    textTop: false,
    lightText: true,
    darkText: true,
    topLine: 'Why Do We Do That',
    headline: 'Open • Fair • Secured • Inclusive',
    description: "The alliance aims to return data soverighty to users",
    buttonDisp: false,
    buttonLabel: 'Contact Us',
  };

  export const section3 = {
    id: 'how',
    bgPlain: false,
    bgImg: require('./images/ideas.jpg').default,
    primary: false,
    lightBg: false,
    textLeft: true,
    textTop: false,
    lightText: true,
    darkText: true,
    topLine: 'How Do We Do That',
    headline: 'Calling All Web3 Builders',
    description:
      "Despite the many definition of web3, we believe it is the framework for the next generation internet and www where data, protocols, applications are created, consumed and owned by users and not the privileged few. We are calling all who embraces web3 to join the W3A DAO and become one of the building blocks in the web3 metaverse.  Verticals we are currently working on are • Social network protocol • Game open protocol • User profile & identity • Interoperable domain name",
    linkTo:'contact',
    buttonDisp: true,
    buttonLabel: 'Join Us'
  };
